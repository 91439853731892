import React from 'react'

import Img from 'gatsby-image'
import ConditionalWrapper from './ConditionalWrapper'

interface ImageProps {
  imageInfo: any
  position?: string
  externalLink?: string
}

const Image = ({ imageInfo, position, externalLink }: ImageProps) => {
  const { alt = '', childImageSharp, image, extension, publicURL } = imageInfo

  let iStyle = { objectFit: 'contain' }
  if (position) {
    iStyle['objectPosition'] = position
  }

  const hasLink = externalLink ? true : false

  // svg support - https://github.com/gatsbyjs/gatsby/issues/10297#issuecomment-464834529
  if (!childImageSharp && extension === 'svg') {
    return <img src={publicURL} alt={alt} className="img__rel" loading="lazy" />
  }

  if (!!image && !!image.childImageSharp) {
    return (
      <ConditionalWrapper
        condition={hasLink}
        wrapper={(children) => (
          <a
            href={externalLink}
            className="anchor__simple block"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        )}
      >
        <Img
          fluid={image.childImageSharp.fluid}
          alt={alt}
          style={{ maxWidth: '100%' }}
          imgStyle={iStyle}
        />
      </ConditionalWrapper>
    )
  }

  if (!!childImageSharp) {
    return (
      <ConditionalWrapper
        condition={hasLink}
        wrapper={(children) => (
          <a
            href={externalLink}
            className="anchor__simple block"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        )}
      >
        <Img
          fluid={childImageSharp.fluid}
          alt={alt}
          style={{ maxWidth: '100%' }}
          imgStyle={iStyle}
        />
      </ConditionalWrapper>
    )
  }

  if (!!image && typeof image === 'string') return <img src={image} alt={alt} />

  return null
}

export default Image
