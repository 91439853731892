import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@/components/Layout'
import Image from '@/components/Image'


const DojoPage = ({ data }) => {
  return (

    <Layout
      pageTitle="Dojo"
      heroTitle="Dojo"
      heroSubTitle=""
      heroTagLine=""
      heroImage=""
      metaDescription="Verken onze dojo. Wist je dat deze van de club is?"
    >

      <div className="row pb-3 text-center">

        <div className="col-12 col-md-6 pb-3">

          <Image imageInfo={data.dojoIngang} />
          <p>
            Inkom Jan De Windstraat 59 – Aalst
          </p>
        </div>

        <div className="col-12 col-md-6 pb-3">

          <Image imageInfo={data.dojoGang} />
          <p>
            Gang met etalages, kleedkamers, toiletten
          </p>
        </div>

        <div className="col-12 col-md-6">
          <Image imageInfo={data.dojo01} />

        </div>

        <div className="col-12 col-md-6">
          <Image imageInfo={data.dojo02} />
        </div>

        <div className="col-12 pb-3">
          <p>
            Ruime dojo met 156m<sup>2</sup> tatami
          </p>

        </div>

        <div className="col-12 col-md-6 pb-3">

          <Image imageInfo={data.cafetaria02} />
          <p>
            Cafetaria met zicht op dojo
          </p>
        </div>
        <div className="col-12 col-md-6 pb-3">

          <Image imageInfo={data.cafetaria01} />
          <p>
            Gezellige en ruime cafetaria (60 m<sup>2</sup>)
          </p>
        </div>
      </div>

    </Layout>
  )
}

export default DojoPage


export const query = graphql`
  query {
    cafetaria01: file(relativePath: {eq: "brand/cafetaria01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    cafetaria02: file(relativePath: {eq: "brand/cafetaria02.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    dojoIngang: file(relativePath: {eq: "brand/dojo-ingang.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    dojoGang: file(relativePath: {eq: "brand/dojo-gang.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    dojo01: file(relativePath: {eq: "brand/dojo01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    dojo02: file(relativePath: {eq: "brand/dojo02.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }



`
